import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { withTheme, ThemeContext } from "styled-components"
import { Helmet } from "react-helmet"

import { Waypoint } from "react-waypoint"
import { useSpring, useTrail, animated, config } from "react-spring"

import bgHero from "../images/backgrounds/lead_hero_bg.jpg"
import ElectricalToolsImage from "../images/electrical_illustrations_1200.jpg"
import Icon from "../utilities/icons"
import DomesticIcon from "../images/icons/icon_domestic_orange.png"
import CommercialIcon from "../images/icons/icon_commercial_orange.png"
import IndustrialIcon from "../images/icons/icon_industrial_orange.png"
import cardLinkLocations from "../images/cardlink-map.jpg"
import cardLinkServices from "../images/cardlink-services.jpg"
import cardLinkContact from "../images/cardlink-contact.jpg"
import BackgroundImage from "gatsby-background-image"
import paragraphs from "lines-to-paragraphs"

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  color: #fff;
  background: #070b16;
  background: rgb(149, 52, 173);
  /*background: linear-gradient(
    0deg,
    ${props => props.theme.yellow},
    ${props => props.theme.orange}
  );*/
  background:#333;
  background-image:url(${props => props.bg_image ? props.bg_image : bgHero});
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  display: flex;
  align-items: center;
  min-height:400px;

  .inner-container {
    display: flex;
    @media (max-width: 840px) {
      flex-direction: column;
    }
  }

  .hero-content_left {
    flex: 1;
    max-width: calc(100%);
    padding: 0 0 20px 0;
    text-align:center;

    @media (max-width: 840px) {
      padding: 40px 0;
      text-align: center;
    }
  }

  .hero-content_right {
    display: none;
    align-items: flex-end;

    img {
      max-width: 400px;
      display: block;
      margin-left: 50px;
      @media (max-width: 840px) {
        margin: 0 auto;
        max-width: 200px;
      }
    }
  }

  h1 {
    color: #fff;
    margin-bottom:0;
    text-transform:uppercase;
    font-size:3rem;
    font-weight:700;
    text-shadow: 1px 1px 0 #000, 2px 2px 0 #000;

    @media (max-width:900px) {
      font-size:2em;
    }

  }

  h3 {
    color: #fff;
    margin:10px 0 20px 0;
    font-size:2rem;
    font-weight:700;
    text-shadow: 1px 1px 0 #000, 2px 2px 0 #000;

    @media (max-width:900px) {
      font-size:2em;
    }
  }
  p {
    color: #fff;
    text-shadow: 1px 1px 0 #000, 2px 2px 0 #000;
    font-size:1.3rem;
    margin-top:5px;
  }
`

const HeroContentGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  max-width: 400px;
  margin: 20px auto 0 auto;

  .hero-content-grid-item {
    @media (max-width: 500px) {
      margin-top: 20px;
    }
  }

  .hero-content-grid-item-icon {
    width: 50px;
    margin: 0 auto;
    img {
      display: block;
      max-width: 100%;
      max-height: 50px;
      margin: 0 auto;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 700;
    text-shadow: 1px 1px 0 #000, 2px 2px 0 #000;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const AngledLayer = styled.div`
  width: 100%;
  height: 500px;
  overflow: hidden;
  transform: skewY(${props => (props.upslope ? "5deg" : "-5deg")});
  position: ${props => props.position};
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  transform-origin: 0;
  background: #d53f8c; /* fallback for old browsers */
  background: #ffdce0;
  /*
  background: linear-gradient(
    to bottom,
    #ef017c,
    #d60063
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  @media (max-width: 900px) {
    height: auto;
    padding: 40px 0 100px 0;
  }

  @media (max-width: 600px) {
    height: auto;
    padding: 40px 0 20px 0;
  }
`

const Section = styled.section`
  position: relative;
  padding: 80px 0;

  @media (max-width: 900px) {
    padding: 40px 0;
  }

  .side-paras {
    max-width: 60%;

    @media (max-width: 900px) {
      max-width: 100%;
    }
  }
  .inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
  .beautician-side {
    width: calc(40% - 20px);
    display: block;
    height: auto;
    align-self: center;

    @media (max-width: 900px) {
      width: 100%;
      margin-top: 40px;
    }
  }
`

const BeautyIconBubble = styled.div`
  width: calc(33.33% - 10px);
  padding-bottom: calc(33.33% - 10px);
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffe9ed;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: auto;

  @media (max-width: 900px) {
    width: calc(11.11% - 10px);
    padding-bottom: calc(11.11% - 10px);
  }
  @media (max-width: 700px) {
    width: calc(20% - 10px);
    padding-bottom: calc(20% - 10px);
    margin-right: 10px;
    &:nth-of-type(5),
    &:nth-of-type(9) {
      margin-right: 0;
    }
  }
`

const ProfileCard = styled.section`
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .profile-card-img {
    width: calc(100%);
    overflow: hidden;
    position: relative;
    padding: 0 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      flex-wrap: nowrap;
    }

    @media (max-width: 700px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    /*  &:after {
      content: "";
      background: #ffe6ff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 60px;
      border-radius: 10px;
      z-index: -1;
    } */
  }

  .profile-card-details {
    width: calc(50% - 10px);
  }

  .profile-card-details-item {
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .profile-card-details-item-label {
    color: #ef017c;
    font-size: 13px;
    line-height: 10px;
    display: block;
    font-weight: 700;
  }

  .profile-card-details-item-desc {
    display: block;
  }
`

const CardLinks = styled.section`
  position: relative;
  background: #f1f5f8;
  padding-top: 40px;

  p {
    max-width: 60%;
  }
`

const LinkCardContainer = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;

  .inner-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .CardLink {
    width: calc(33.33% - 30px);
    transition: all 0.2s ease-out;
    cursor: pointer;
    margin-bottom: 20px;

    .upper {
      transform-origin: 0;
      background: rgba(255, 255, 255, 1);
      border-radius: 5px;
      padding: 20px 20px 40px 20px;
      position: relative;
      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
      border-top: 2px solid ${props => props.theme.orange};

      h4 {
        /*transform: rotate(-5deg) skew(-5deg);*/
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        /*transform: rotate(-5deg) skew(-5deg);*/
        max-width: 100%;
        width: 100%;
        line-height: 20px;
      }
    }
    .lower {
      width: 100%;
      /*transform: rotate(-5deg) skew(-5deg);*/
      margin-top: -50px;
      margin-left: 10px;

      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      transition: all 0.15s ease-out;

      img {
        width: 100%;
        height: 150px;
        display: block;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .cardLink-arrow {
      background: #fff;
      margin-top: -25px;
      border-radius: 5px;
      height: 50px;
      position: relative;
      z-index: -1;
      box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);

      .right-arrow-icon {
        position: absolute;
        bottom: -5px;
        right: -5px;
        /*transform: rotate(-5deg) skew(-5deg);*/
        transition: all 0.2s ease-out;
      }
    }

    &:hover {
      /*margin-top: -5px;*/
      transform: translateY(-5px) !important;

      .upper {
        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2);
      }

      .cardLink-arrow {
        box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2);
      }

      /*.right-arrow-icon {
      bottom: 5px;
      right: 5px;
    }*/
    }

    @media (max-width: 900px) {
      width: calc(50% - 20px);
    }

    @media (max-width: 600px) {
      width: calc(100%);
      margin-bottom: 40px;
    }
  }
`

const IndexPage = ({ location, theme, data }) => {
  //console.log(location.pathname)
  //const themeContext = useContext(ThemeContext)
  //console.dir(themeContext)

  console.log("data = ")
  console.dir(data)

  const content_data =
    data.content.edges[0].node.childMarkdownRemark.frontmatter

  const image_data = data.images.edges[0].node.childImageSharp.fluid


  const [on, toggle] = useState(false)
  const animation = useSpring({
    //transform: on ? "translateY(0px)" : "translateY(100%)",
    bottomOffset: "0",
  })

  const fadeInLeft = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(-500px)`,
    },
    to: {
      opacity: 1,
      transform: `translateX(0)`,
    },
  })

  const fadeInRight = useSpring({
    from: {
      opacity: 0,
      transform: `translateX(500px)`,
    },
    to: {
      opacity: 1,
      transform: `translateX(0)`,
    },
  })

  const trail = useTrail(3, {
    opacity: on ? 1 : 0,
    transform: on ? "translateY(0)" : "translateY(400px)",
  })

  var card_links = {
    0: {
      title: "Services",
      text: "See the range of services provided by MJ Electrical.",
      url: "/services",
      img_src: content_data.service_link_card_image ? content_data.service_link_card_image : cardLinkServices,
      img_alt: "Electrician in fuseboard",
    },
    1: {
      title: "Locations",
      text: "See the areas MJ Electrical cover across the East Midlands.",
      url: "/locations",
      img_src: content_data.location_link_card_image ? content_data.location_link_card_image : cardLinkLocations,
      img_alt: "Map of East Midlands",
    },
    2: {
      title: "Contact",
      text: "Get in touch with MJ Electrical today.",
      url: "/contact",
      img_src: content_data.contact_link_card_image ? content_data.contact_link_card_image : cardLinkContact,
      img_alt: "Image of user on a laptop",
    },
  }

  return (
    <Layout location={location.pathname}>
      <SEO
        title="MJ Electrical | Electrician in Newark"
        description="Electrician in Newark on Trent and surrounding areas. Covering Nottinghamshire, Lincolnshire, Leicestershire, Derbyshire"
      />
      <Helmet
        script={[
          {
            src: "https://identity.netlify.com/v1/netlify-identity-widget.js",
            type: "text/javascript",
          },
        ]}
      />
      {
        /*
        style={`background-image:url("${content_data.thumbnail}")`}
        */
      }
      <HeroContent bg_image={content_data.thumbnail}>
        <div className="inner-container">
          <animated.div style={fadeInLeft} className="hero-content_left">
            <h2 style={{ marginBottom: "0", fontSize: "20px" }}>
              MJ Electrical
            </h2>
            <h1 style={{ textIndent: "-9999px", height: 0 }}>Electrician in Newark</h1>
            <h3>Specialists in Electrical Installation in Newark <br />&amp; Surrounding Areas</h3>
            <div className="global-btn-wrapper">
              {/*<Link className="global-btn global-btn_white" to="/contact">
                Get in Touch
              </Link>*/}
            </div>
            <HeroContentGrid>
              <div className="hero-content-grid-item">
                <div className="hero-content-grid-item-icon">
                  <img src={DomesticIcon} alt="Domestic Icon" />
                </div>
                <h3>Domestic</h3>
              </div>
              <div className="hero-content-grid-item">
                <div className="hero-content-grid-item-icon">
                  <img src={CommercialIcon} alt="Commercial Icon" />
                </div>
                <h3>Commercial</h3>
              </div>
              <div className="hero-content-grid-item">
                <div className="hero-content-grid-item-icon">
                  <img src={IndustrialIcon} alt="Industrial Icon" />
                </div>
                <h3>Industrial</h3>
              </div>
            </HeroContentGrid>
          </animated.div>
          {/*<animated.div style={fadeInRight} className="hero-content_right">
              <img
                src={
                  {
                    LeadHeroImg
                  }
                }
              />
            </animated.div>*/}
        </div>
      </HeroContent>

      <Section>
        <div className="inner-container">
          <div className="side-paras">
            <div
              dangerouslySetInnerHTML={{
                __html: paragraphs(content_data.body_content_po),
              }}
            />

            <div style={{ marginTop: "0px" }} className="global-btn-wrapper ">
              <Link className="global-btn global-btn_orange" to="/contact">
                Get in Touch
              </Link>
            </div>
          </div>
          <div className="beautician-side">
            <ProfileCard>
              <div className="profile-card-img">
                <img src={content_data.paragraph_one_image ? content_data.paragraph_one_image : ElectricalToolsImage} alt="Electrical Tools" />
              </div>
            </ProfileCard>
          </div>
        </div>
      </Section>
      <Waypoint
        topOffset="-100px"
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <CardLinks>
        {/*<AngledLayer style={{ background: "#F1F5F8" }} upslope grey>*/}
        <LinkCardContainer>
          <div className="inner-container">
            {trail.map((props, i) => (
              <animated.div style={props} className="CardLink">
                <Link to={card_links[i]["url"]}>
                  <div className="upper">
                    <h4>{card_links[i]["title"]}</h4>
                    <p>{card_links[i]["text"]}</p>
                  </div>
                  <div className="lower">
                    <img
                      src={card_links[i]["img_src"]}
                      alt={card_links[i]["img_alt"]}
                    />
                  </div>
                  <div className="cardLink-arrow">
                    <Icon name="right_arrow" height="24" />
                  </div>
                </Link>
              </animated.div>
            ))}
          </div>
        </LinkCardContainer>
        {/*</AngledLayer>*/}
      </CardLinks>
    </Layout >
  )
}

export default withTheme(IndexPage)

export const query = graphql`
  query Content {
    content: allFile(filter: { name: { eq: "homepage_content" } }) {
      edges {
        node {
          childMarkdownRemark {
            id
            html
            frontmatter {
              body_content_po
              thumbnail
            }
          }
        }
      }
    }
    images: allFile(
      filter: { relativePath: { eq: "backgrounds/lead_hero_bg.jpg" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            id
            resolutions {
              base64
              tracedSVG
              aspectRatio
              width
              height
              src
              srcSet
              srcWebp
              srcSetWebp
              originalName
            }
          }
        }
      }
    }
  }
`
